import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import EnterZip from '../components/vote-early/EnterZip'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import stateNames from '../constants/stateNames'

function IndexPage({ nonPartisan = false, share = false }) {
  const router = useRouter()
  const stateAbbr = router.query.state?.toUpperCase()
  const stateName = stateNames[stateAbbr]

  // if we have one, then set the view to load the state info
  useEffect(() => {
    if (stateName) router.push(`/state/${stateAbbr.toLowerCase()}`)
  }, [stateName])

  return (
    <div id={nonPartisan ? 'vote-page' : 'vote-early'}>
      <Layout fullScreen nonPartisan={nonPartisan} share={share}>
        <EnterZip nonPartisan={nonPartisan} />
      </Layout>
    </div>
  )
}

IndexPage.propTypes = {
  nonPartisan: PropTypes.bool,
  share: PropTypes.bool,
}

export default IndexPage
