import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button, Form } from 'react-bootstrap'
import { getStateFromZip } from '../../lib/getState'
import { logPageView, logEvent, logSegIdentity, logSegEvent, logSegPage } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import { isMobileDevice } from '../../lib/sharing'
import { ZIP_CODE_REGEX } from '../../globals'
const biden = '/static/landslide/images/hipster-biden-sm.png'
const trump = '/static/landslide/images/hipster-trump-sm.png'
const bidenWebP = '/static/landslide/images/hipster-biden-sm.webp'
const trumpWebP = '/static/landslide/images/hipster-trump-sm.webp'
import CaptainImg from './CaptainImg'
import NonPartisanCopy from './NonPartisanCopy'

const experiment = 'desktop-zip-page'

function EnterZip({ nonPartisan }) {
  const [zip, setZip] = useState('')
  const [pending, setPending] = useState(false)
  const [error, setError] = useState('')
  const router = useRouter()
  // const [copyVersion] = useState(Math.round(Math.random()) + 1)
  // const version = copyVersion

  useEffect(() => {
    if (nonPartisan) {
      logPageView(`landing-non-partisan`)
      logSegPage(`landing-non-partisan`)
    } else {
      logPageView(`landing`)
      logSegPage(`landing`)
    }
  }, [])

  const handleZipSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const zipCodeValid = new RegExp(ZIP_CODE_REGEX).test(zip)
    if (!zipCodeValid) {
      setError('Zip code is missing or incorrect')
      return
    }
    setPending(true)
    try {
      const st = getStateFromZip(parseInt(zip))
      logSegIdentity(null, { zip, landingCopyVersion: 3 })
      logSegEvent(BUTTON_CLICK, { experiment, variant: nonPartisan ? `landing-zip-non-partisan` : `landing-zip-new` })
      logEvent({ category: 'voting', action: 'zip entry', label: isMobileDevice ? 'mobile' : 'desktop', value: zip })
      router.push(`/state/${st.abbr.toLowerCase()}?zip=${zip}`)
    } catch (e) {
      console.log('e', e)
    }
  }
  return (
    <div id="enter-zip">
      <div>
        <header>
          <h1>Landslide</h1>
          {!nonPartisan && <CaptainImg />}
          {nonPartisan && <NonPartisanCopy />}
          {!nonPartisan && (
            <div id="copy-1">
              <h2>Trump is trying to steal the election.</h2>
              <h2 className="blue">Find out how you can stop him.</h2>
            </div>
          )}
          {/* <div id="copy-2">
            <h2 className="blue">Vote safe.</h2>
            <h2>Make sure your vote is counted by Election Night.</h2>
            <h3>Get the best voting options for your state. Learn when it's better to:</h3>
            <p>+ vote by mail</p>
            <p>+ vote early in person</p>
            <p>+ vote in person on Nov 3</p>
          </div> */}
        </header>
        <main>
          <Form onSubmit={handleZipSubmit}>
            <Form.Group>
              <Form.Control
                autoFocus
                className="zip-input"
                name="zipcode"
                placeholder={'ENTER YOUR ZIP CODE'}
                onChange={(e) => {
                  setZip(e.currentTarget.value)
                  setError('')
                }}
                type="number"
                value={zip}
              />
            </Form.Group>
            <Button id="zip-btn" block type="submit">
              {pending ? 'Checking' : 'See Your Voting Options'}
            </Button>
            {error && <p className="error">{error}</p>}
          </Form>
        </main>
      </div>
      <div>
        {!nonPartisan && (
          <div className="image-wrapper">
            <picture className="biden">
              <source srcSet={bidenWebP} type="image/webp" />
              <source srcSet={biden} type="image/jpeg" />
              <img src={biden} alt="Hipster Biden" />
            </picture>
            <picture className="trump">
              <source srcSet={trumpWebP} type="image/webp" />
              <source srcSet={trump} type="image/jpeg" />
              <img src={trump} alt="Hipster Trum" />
            </picture>
          </div>
        )}
        <footer>
          {nonPartisan ? <p>Paid for by Landslide PAC</p> : <p>Paid for by Landslide PAC - Illustrations by @hipstoryart</p>}
          <div>
            <a href="https://secure.actblue.com/donate/jointhelandslide" target="_blank" rel="noreferrer">
              DONATE
            </a>
            {isMobileDevice && (
              <Link
                href={{
                  pathname: '/login',
                }}
              >
                LOGIN
              </Link>
            )}
            <Link
              href={{
                pathname: '/faq',
                query: { back: 'vote-early' },
              }}
            >
              FAQ
            </Link>
            <Link
              href={{
                pathname: '/privacy',
                query: { back: 'vote-early' },
              }}
            >
              PRIVACY
            </Link>
            <Link
              href={{
                pathname: '/tou',
                query: { back: 'vote-early' },
              }}
            >
              TERMS
            </Link>
          </div>
        </footer>
      </div>
    </div>
  )
}

EnterZip.defaultProps = {
  flashScreen: false,
  nonPartisan: false,
}

EnterZip.propTypes = {
  flashScreen: PropTypes.bool,
  nonPartisan: PropTypes.bool,
  version: PropTypes.number,
}

export default EnterZip
